<template>
	<div>
		<span v-show="loading" class="text-success font-weight-bold">Generate language...</span>
		<v-select v-show="!loading" v-model="mylocale" placeholder="Language" style="width: 125px" :options="langsCollection" @input="ChangeLanguage($event)" />
	</div>
</template>

<script>
	import { mapActions } from "vuex"

	export default {
		name: "LanguageSwitcher",
		data() {
			return {
				langsCollection: [
					{ id: "1", label: "en" },
					{ id: "2", label: "id" },
				],
				loading: false,
				mylocale: {
					id: "1",
					label: "en",
				},
			}
		},
		async mounted() {
			this.mylocale = JSON.parse(localStorage.getItem("language"))
			await this.ActionLang(this.mylocale)
		},
		methods: {
			...mapActions({
				ActionLang: "LANG/ActionLang",
			}),
			async ChangeLanguage(lang) {
				let payload = ""
				if (lang !== null) {
					payload = {
						id: lang.id,
						label: lang.label,
					}
				} else {
					payload = {
						id: "1",
						label: "en",
					}
				}

				this.loading = true
				const res = await this.ActionLang(payload)
				if (res) {
					this.loading = false
				}
				localStorage.setItem("language", JSON.stringify(payload))
			},
		},
	}
</script>
