<template>
	<div
		class="side-content-wrap"
		@mouseenter="isMenuOver = true"
		@mouseleave="isMenuOver = false"
		@touchstart="isMenuOver = true"
	>
		<vue-perfect-scrollbar
			ref="myData"
			:settings="{ suppressScrollX: true, wheelPropagation: false }"
			:class="{ open: getSideBarToggleProperties.isSideNavOpen }"
			class="sidebar-left rtl-ps-none ps scroll"
		>
			<div>
				<ul class="navigation-left">
					<li
						v-for="(item, idx) in menu"
						:key="idx"
						class="nav-item"
						:class="{ active: selectedParentMenu == item.MenuId }"
						:data-item="item.MenuId"
						:data-submenu="item.Child ? true : false"
						@mouseenter="toggleSubMenu"
					>
						<a
							v-if="item.Child"
							class="nav-item-hold"
							href=""
						>
							<i
								class="nav-icon"
								:class="item.MenuIcon"
							/>
							<span class="nav-text">{{ item.MenuName }}</span>
						</a>
						<router-link
							v-else
							:to="item.MenuModule"
							class="nav-item-hold"
						>
							<i
								class="nav-icon"
								:class="item.MenuIcon"
							/>
							<span class="nav-text">{{ item.MenuName }}</span>
						</router-link>
						<div :class="item.Child?'triangle':''" />
					</li>
				</ul>
			</div>
		</vue-perfect-scrollbar>

		<vue-perfect-scrollbar
			:class="{ open: getSideBarToggleProperties.isSecondarySideNavOpen }"
			:settings="{ suppressScrollX: true, wheelPropagation: false }"
			class="sidebar-left-secondary ps rtl-ps-none"
		>
			<div ref="sidebarChild">
				<!-- Submenu Dashboards -->
				<ul
					v-for="(item, idx) in menu"
					:key="idx"
					class="childNav d-none"
					:data-parent="item.MenuId"
					:class="{ 'd-block': selectedParentMenu == item.MenuId }"
				>
					<li
						v-for="(Child, childId) in item.Child"
						:key="childId"
						class="nav-item"
					>
						<router-link :to="Child.MenuModule">
							<i
								class="nav-icon"
								:class="Child.MenuIcon"
							/>
							<span class="item-name">{{ Child.MenuName }}</span>
						</router-link>
					</li>
				</ul>
			</div>
		</vue-perfect-scrollbar>
		<div
			class="sidebar-overlay"
			:class="{ open: getSideBarToggleProperties.isSecondarySideNavOpen }"
			@click="removeOverlay()"
		/>
	</div>
	<!--=============== Left side End ================-->
</template>

<script>
	import { isMobile } from "mobile-device-detect"
	import { mapGetters, mapActions } from "vuex"

	export default {
		data() {
			return {
				isDisplay: true,
				isMenuOver: false,
				isStyle: true,
				selectedParentMenu: "",
				isMobile,
			}
		},
		computed: {
			...mapGetters({
				getSideBarToggleProperties: "LARGESIDEBAR/getSideBarToggleProperties",
			}),
			menu() {
				let datmen = JSON.parse(localStorage.getItem("userInfo"))
				return datmen.menu
			},
		},
		mounted() {
			this.toggleSelectedParentMenu()
			window.addEventListener("resize", this.handleWindowResize)
			document.addEventListener("click", this.returnSelectedParentMenu)
			this.handleWindowResize()
		},
		beforeDestroy() {
			document.removeEventListener("click", this.returnSelectedParentMenu)
			window.removeEventListener("resize", this.handleWindowResize)
		},
		methods: {
			...mapActions({
				changeSecondarySidebarProperties: "LARGESIDEBAR/changeSecondarySidebarProperties",
				changeSecondarySidebarPropertiesViaMenuItem: "LARGESIDEBAR/changeSecondarySidebarPropertiesViaMenuItem",
				changeSecondarySidebarPropertiesViaOverlay: "LARGESIDEBAR/changeSecondarySidebarPropertiesViaOverlay",
				changeSidebarProperties: "LARGESIDEBAR/changeSidebarProperties",
			}),
			handleWindowResize() {
				//  console.log('not working is Mobile');
				if (window.innerWidth <= 1200) {
					if (this.getSideBarToggleProperties.isSideNavOpen) {
						this.changeSidebarProperties()
					}
					if (this.getSideBarToggleProperties.isSecondarySideNavOpen) {
						this.changeSecondarySidebarProperties()
					}
				} else if (!this.getSideBarToggleProperties.isSideNavOpen) {
					this.changeSidebarProperties()
				}
			},
			toggleSelectedParentMenu() {
				this.selectedParentMenu = this.$route.meta.ParentId
			},
			toggleSubMenu(e) {
				let hasSubmenu = e.target.dataset.submenu
				let parent = e.target.dataset.item
				if (hasSubmenu) {
					this.selectedParentMenu = parent

					this.changeSecondarySidebarPropertiesViaMenuItem(true)
				} else {
					this.selectedParentMenu = parent
					this.changeSecondarySidebarPropertiesViaMenuItem(false)
				}
			},

			removeOverlay() {
				this.changeSecondarySidebarPropertiesViaOverlay()
				if (window.innerWidth <= 1200) {
					this.changeSidebarProperties()
				}
				this.toggleSelectedParentMenu()
			},
			returnSelectedParentMenu() {
				if (!this.isMenuOver) {
					this.toggleSelectedParentMenu()
				}
			},

			toggleSidebarDropdwon(event) {
				let dropdownMenus = this.$el.querySelectorAll(".dropdown-sidemenu.open")

				event.currentTarget.classList.toggle("open")

				dropdownMenus.forEach((dropdown) => {
					dropdown.classList.remove("open")
				})
			},
		},
	}
</script>

<style lang="" scoped></style>
