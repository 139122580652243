<template>
	<div>
		<b-dropdown size="lg" variant="link" toggle-class="text-decoration-none" no-caret>
			<template #button-content>
				<span>
					<img :src="icondropdowngroup" />
				</span>
			</template>
			<div class="text-center labelgroup">Groups</div>
			<b-dropdown-item v-for="(item, idx) in listgroup" :key="idx" class="dropdowngroup" @click.native.capture.stop>
				<b-row align-v="center">
					<b-col cols="9" align-v="center"> <img :src="iconitemgroup" class="imgicongroup" />{{ item.GroupName }} </b-col>
					<b-col cols="3">
						<label class="switch switch-primary mr-3">
							<input type="checkbox" :checked="activenow === item.UserGroupGroupId ? true : false" @change="changeGroup(item.UserGroupGroupId)" />
							<span class="slider" />
						</label>
					</b-col>
				</b-row>
			</b-dropdown-item>
		</b-dropdown>
	</div>
</template>

<script>
	import IconDropDownGroup from "@/assets/images/icon/icondropdowngroup.png"
	import IconItemGroup from "@/assets/images/icon/iconusers.png"

	export default {
		name: "GroupSwitcher",
		data() {
			return {
				icondropdowngroup: IconDropDownGroup,
				iconitemgroup: IconItemGroup,
				checked: false,
			}
		},
		computed: {
			listgroup() {
				let datgroup = JSON.parse(localStorage.getItem("userInfo"))
				return datgroup.Groups
			},
			activenow() {
				let datgroup = JSON.parse(localStorage.getItem("userInfo"))
				return datgroup.GroupId
			},
		},
		methods: {
			changeGroup(_id) {
				// code here
			},
		},
	}
</script>
<style>
	.dropdowngroup {
		width: 350px;
		padding: 0.5rem;
	}
	.labelgroup {
		background-color: #b3b3b3;
		color: white;
		font-weight: bold;
		padding: 3px;
	}
	.dropdowncontent {
		margin-top: 25px !important;
	}
</style>
