<template>
	<div class="main-header">
		<div class="logo">
			<img :src="require('@/assets/images/koltipay-logo-lite.svg')" />
		</div>

		<img style="padding-left: 30px; cursor: pointer" :src="iconburger" @click="sideBarToggle" />
		<!-- <div class="menu-toggle" @click="sideBarToggle">
			<div />
			<div />
			<div />
		</div> -->

		<div style="margin: auto" />

		<div class="header-part-right">
			<GroupSwitcher />
			<div style="width: 20px" />
			<!--Language-->
			<LanguageSwitcher />
			<!--language-->
			<div style="width: 20px" />

			<!-- Full screen toggle -->
			<!-- <i class="i-Full-Screen header-icon d-none d-sm-inline-block" @click="handleFullScreen" /> -->
			<!-- <i class="i-Full-Screen header-icon d-none d-sm-inline-block" data-fullscreen></i> -->
			<!-- Grid menu Dropdown -->

			<!-- User avatar dropdown -->
			<div class="dropdown">
				<b-dropdown
					id="dropdown-1"
					text="Dropdown Button"
					class="m-md-2 user col align-self-end"
					toggle-class="text-decoration-none"
					no-caret
					variant="link"
				>
					<template #button-content>
						<img
							id="userDropdown"
							:src="require('@/assets/images/faces/1.jpg')"
							alt
							data-toggle="dropdown"
							aria-haspopup="true"
							aria-expanded="false"
						/>
					</template>

					<div class="dropdown-menu-right" aria-labelledby="userDropdown">
						<div class="dropdown-header"><i class="i-Lock-User mr-1" /> {{ nameUser }}</div>
						<!-- <a class="dropdown-item">Account settings</a> -->
						<a class="dropdown-item" href="#" @click.prevent="logoutUser">Sign out</a>
					</div>
				</b-dropdown>
			</div>
		</div>
	</div>

	<!-- header top menu end -->
</template>
<script>
	import { isMobile } from "mobile-device-detect"
	import { mapGetters, mapActions } from "vuex"
	import { mixin as clickaway } from "vue-clickaway"
	import Util from "@/utils"

	import LanguageSwitcher from "@/components/LanguageSwitcher.vue"
	import GroupSwitcher from "@/components/GroupSwitcher.vue"

	import IconBurger from "@/assets/images/icon/iconburger.png"

	export default {
		components: {
			LanguageSwitcher,
			GroupSwitcher,
		},
		mixins: [clickaway],
		data() {
			return {
				iconburger: IconBurger,
				isDisplay: true,
				isStyle: true,
				isMouseOnMegaMenu: true,
				isMegaMenuOpen: false,
			}
		},
		computed: {
			...mapGetters({ getSideBarToggleProperties: "LARGESIDEBAR/getSideBarToggleProperties" }),
			nameUser() {
				let dt = JSON.parse(localStorage.getItem("userInfo"))
				return dt.UserRealName
			},
		},
		mounted() {
			// document.addEventListener("click", this.closeMegaMenu);
		},
		methods: {
			...mapActions({
				logoutJWT: "AUTHDATA/logoutJWT",
			}),
			...mapActions({
				changeSecondarySidebarProperties: "LARGESIDEBAR/changeSecondarySidebarProperties",
				changeSidebarProperties: "LARGESIDEBAR/changeSidebarProperties",
			}),
			handleFullScreen() {
				Util.toggleFullScreen()
			},
			logoutUser() {
				this.logoutJWT()

				this.$router.push("/app/sessions/signIn")
			},
			sideBarToggle() {
				if (this.getSideBarToggleProperties.isSideNavOpen && this.getSideBarToggleProperties.isSecondarySideNavOpen && isMobile) {
					this.changeSidebarProperties()
					this.changeSecondarySidebarProperties()
				} else if (this.getSideBarToggleProperties.isSideNavOpen && this.getSideBarToggleProperties.isSecondarySideNavOpen) {
					this.changeSecondarySidebarProperties()
				} else if (this.getSideBarToggleProperties.isSideNavOpen) {
					this.changeSidebarProperties()
				} else if (
					!this.getSideBarToggleProperties.isSideNavOpen &&
					!this.getSideBarToggleProperties.isSecondarySideNavOpen &&
					!this.getSideBarToggleProperties.isActiveSecondarySideNav
				) {
					this.changeSidebarProperties()
				} else if (!this.getSideBarToggleProperties.isSideNavOpen && !this.getSideBarToggleProperties.isSecondarySideNavOpen) {
					// console.log("4");

					this.changeSidebarProperties()
					this.changeSecondarySidebarProperties()
					// console.log("4");
				}
			},
		},
	}
</script>
<style>
	/* .img-header {
		background-color: #54A7DC !important;
		height: 80px !important;
		width: 100% !important;
	} */
</style>
