<template>
	<div class="app-admin-wrap layout-sidebar-large clearfix"> 
		<top-nav v-if="ext === 'no'" />
		<sidebar v-if="ext === 'no'" />
		<main v-if="ext === 'no'">
			<div
				:class="{
					'sidenav-open': getSideBarToggleProperties.isSideNavOpen,
				}"
				class="main-content-wrap d-flex flex-column flex-grow-1 print-area"
				style="margin-top: 50px"
			>
				<transition name="page" mode="out-in">
					<router-view />
				</transition>

				<div class="flex-grow-1" />
			</div>
		</main>

		<main v-if="ext === 'yes'">
			<b-row>
				<b-col md="12"><router-view /></b-col>
			</b-row>
		</main>
	</div>
</template>

<script>
	import Sidebar from "./Sidebar"
	import TopNav from "./TopNav"
	import { mapGetters } from "vuex"

	export default {
		components: {
			Sidebar,
			TopNav,
		},
		data() {
			return {
				ext: null 
			}
		},
		computed: {
			...mapGetters({
				getSideBarToggleProperties: "LARGESIDEBAR/getSideBarToggleProperties",
			}),
		},
		mounted() {
			this.ext = localStorage.getItem("ext")
		},
		methods: {},
	}
</script>
<style></style>
